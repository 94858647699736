

(function($) {

	$(document).ready(function() {

		window.addEventListener('resize', function() {
			var windowWidth = window.innerWidth;
			var heightContainer = document.getElementById('about-container').offsetHeight;
			var containerInsurance = document.getElementById('container-insurance');
			var slides = $('.slides-description').height(heightContainer);

			if(windowWidth > 991) {
				var slides = $('.slides-description').height(heightContainer - 1);
				containerInsurance.style.height = heightContainer - 1;
			}
		});

		$('.carousel-section').owlCarousel({
			animateOut: 'fadeOut',
			animateIn: 'fadeIn',
			items:1,
			smartSpeed:1000,
			autoplay: true,
			autoplayTimeout: 7500,
			loop: true,
			dots: false,
			mouseDrag: false,
			lazyLoad:true,
			touchDrag: false
		});

		$('#quote-aliados').owlCarousel({
			animateIn: 'fadeIn',
			animateOut: 'fadeOut',
			items:1,
			autoplay: true,
			autoplayTimeout: 5000,
			loop: true,
			dots: false,
			mouseDrag: false,
			touchDrag: false,
			center: true,
			autoWidth: false,
			autoHeight: false,
			stagePadding: 0,
			responsive: {
				575: {
					margin: 10
				}
			}
		});

		var owl = $('#text-quote-ctn').owlCarousel({
			animateIn: 'fadeIn',
			animateOut: 'fadeOut',
			items:1,
			autoplay: true,
			autoplayTimeout: 20000,
			loop: true,
			dots: false,
			mouseDrag: false,
			touchDrag: false,
			margin: 150,
			center: true,
			autoWidth: false,
			autoHeight: false,
			responsive: {
				575: {
					margin: 0
				}
			}
		});

		owl.on('translated.owl.carousel', setDelay);
		owl.on('translate.owl.carousel', setDelay);

		function setDelay() {
			var delay = 2000;
			owl.trigger('stop.owl.autoplay');
			setTimeout(function() {
				owl.trigger('play.owl.autoplay');
			}, delay);
		}

		$('#slides-container').owlCarousel({
			items: 3,
			responsiveClass: true,
			dots: false,
			responsive: {
				992: {
					items: 3,
					dots: false
				},
				768: {
					items: 2,
					dots: false,
					autoplay: true,
					autoplayTimeout: 5000,
					loop: true
				},
				320: {
					items: 1,
					dots: false,
					autoplay: true,
					autoplayTimeout: 5000,
					loop: true
				},
				0: {
					items: 1,
					dots: false,
					autoplay: true,
					autoplayTimeout: 5000,
					loop: true
				}
			}
		});

		$('#valores').owlCarousel({
			animateOut: 'fadeOut',
			animateIn: 'fadeIn',
			items:1,
			autoplay: true,
			autoplayTimeout: 3500,
			loop: true,
			dots: false,
			mouseDrag: false,
			touchDrag: false,
			margin: 150
		});

		$('#closeNavigation').hide();

		$('#openNavigation').on('click', function(e) {
			e.preventDefault();
			$('#navContainer')
				.fadeIn(500)
				.addClass('open-navigation');

			$('#navContainer ul li a').fadeIn(250);

			$('.social-icon')
				.removeClass('hide')
				.addClass('show');

			$('#closeNavigation').css('display', 'block');
		});

		$('#closeNavigation').on('click', function(e) {
			e.preventDefault();
			
			$('.social-icon')
				.removeClass('show')
				.addClass('hide');

			$('#navContainer')
				.fadeOut(500)
				.removeClass('open-navigation');

			$('#navContainer ul li a').fadeOut(250);

			setTimeout(function() {
				$('#closeNavigation')
					.fadeOut(250)
					.hide();
			}, 100);
		});

		var totalLogos;
		var lastframe = -1;
		
		var logos_routes = $('.routes-logos');
		var current = [];
		$(logos_routes).each(function() {			
			current.push($(this).attr("data-routes"))
		});
		
		function updateLogos(current){
			var div_current = Math.ceil(current.length / 3);

			var current_update1 = current.slice(0,div_current);

			var current_update2 = current.slice(div_current,div_current*2);

			var current_update3 = current.slice(div_current*2);

			var arr = [current_update1,current_update2,current_update3];
			
			return arr;
		}
		
		var auxRam1;
		var auxRam2;
		var auxRam3;

		console.log('auxRam1'+auxRam1);
		console.log('auxRam2'+auxRam2);
		console.log('auxRam3'+auxRam3);
		var b=0;
		var c=0;		
		function showLogos(current,splice) {
			var vivirOut = current.indexOf('http://rmsegurospty.com/wp-content/uploads/2018/11/LOGOVIVIR.png');
			if(splice >= 1){
				console.log('SPLICE 1');
				current.splice(vivirOut, 1);
			}
			var length = current.length;
			var random1 = Math.floor(Math.random()*length);
			var random2 = Math.floor(Math.random()*length);
			var random3 = Math.floor(Math.random()*length);

			//$('#img-logo-ram-1').attr('src', current[random3]);
	 		//$('#img-logo-ram-2').attr('src', current[random2]);
	 		//$('#img-logo-ram-3').attr('src', current[random1]);

			// var arr1 = arr[0];
			// var arr2 = arr[1];
			// var arr3 = arr[2];
		
			// var arr1lengt = arr[0].length;
			// var arr2lengt = arr[1].length;
			// var arr3lengt = arr[2].length;


		
			// var randLogo1 = Math.floor(Math.random()*arr1lengt);

			// var randLogo2 = Math.floor(Math.random()*arr2lengt);
			
			// var randLogo3 = Math.floor(Math.random()*arr3lengt);
			
			
			//  if(randLogo1==auxRam1 || randLogo2==auxRam2 || randLogo3==auxRam3){

			// 	//var arr = updateLogos(current);

			// 	var randLogo1 = Math.floor(Math.random()*arr1lengt);
				
			// 	var randLogo2 = Math.floor(Math.random()*arr2lengt);
				
			// 	var randLogo3 = Math.floor(Math.random()*arr3lengt);
			// 	//showLogos(arr);
			//  }
			 
			 
			//auxRam1 = random1;
			//auxRam2 = random2;
			//auxRam3 = random3; 


			var a = 0;
  			while (a == 0) {
				if (random1 != random2 && random1 != random3 && random2 != random1 && random2 != random3 && random3 != random1 && random3 != random2 && random1 != auxRam1 && random1 != auxRam2 && random1 != auxRam3 && random2 != auxRam1 && random2 != auxRam2 && random2 != auxRam3 && random3 != auxRam1 && random3 != auxRam2 && random3 != auxRam3) {
					$('#img-logo-ram-1').attr('src', current[random3]);
					$('#img-logo-ram-2').attr('src', current[random2]);
					$('#img-logo-ram-3').attr('src', current[random1]);
					auxRam1 = random1;
					auxRam2 = random2;
					auxRam3 = random3; 

					a = 1;
				}else{

					random1 = Math.floor(Math.random()*length);
					random2 = Math.floor(Math.random()*length);
					random3 = Math.floor(Math.random()*length);
				}
  			}
					
		}

		var numberSplice=0;
		function spliceNum(){
			var dataVivir1 = $('#img-logo-ram-1').attr('data-logo-vivir');
			var dataVivir2 = $('#img-logo-ram-2').attr('data-logo-vivir');
			var dataVivir3 = $('#img-logo-ram-3').attr('data-logo-vivir');
			var logoVivir1 = $('#img-logo-ram-1').attr('src');
			var logoVivir2 = $('#img-logo-ram-2').attr('src');
			var logoVivir3 = $('#img-logo-ram-3').attr('src');
					
			if (logoVivir1 == 'http://rmsegurospty.com/wp-content/uploads/2018/11/LOGOVIVIR.png' || logoVivir2 == 'http://rmsegurospty.com/wp-content/uploads/2018/11/LOGOVIVIR.png' || logoVivir3 == 'http://rmsegurospty.com/wp-content/uploads/2018/11/LOGOVIVIR.png') {
				b++;
				$('#img-logo-ram-1').attr('data-logo-vivir', b);
				$('#img-logo-ram-2').attr('data-logo-vivir', b);
				$('#img-logo-ram-3').attr('data-logo-vivir', b);				

				if (dataVivir1 >= 3 || dataVivir2 >= 3 || dataVivir3 >= 3) {
					var numberSplice = 1;
					//
					// while (logoVivir1 == 'http://rmsegurospty.com/wp-content/uploads/2018/11/LOGOVIVIR.png' || logoVivir2 == 'http://rmsegurospty.com/wp-content/uploads/2018/11/LOGOVIVIR.png' || logoVivir3 == 'http://rmsegurospty.com/wp-content/uploads/2018/11/LOGOVIVIR.png') {
					// 	$('#img-logo-ram-1').attr('src', current[random3]);
					// 	$('#img-logo-ram-2').attr('src', current[random2]);
					// 	$('#img-logo-ram-3').attr('src', current[random1]);
					// 	c++;
					// 	if (c > 5) {
					// 		c =0;
					// 		break;
					// 	}
					// }	
					
				}

			} 			
			return numberSplice;
		}
		// function updateLogo(num){
		// 	var nextImage = Math.floor(Math.random()*totalLogos);
		// 	for (var i = 0; i<5; i++) {
		// 		if (nextImage == current[i]) {
		// 			//setTimeout(updateLogo(num),  3500);
		// 			return
		// 		}
		// 	}

		// 	for (var i = 0; i<totalLogos; i++) {
		// 		var logo = jQuery('.companies:eq('+num+') .company:eq('+i+')');
		// 		if (logo.css('opacity') > .5) {
		// 			if (nextImage == i) {
		// 				//setTimeout(updateLogo(num), 3500);
		// 				return
		// 			}
		// 			TweenMax.to(logo, .75, {autoAlpha:0});
		// 		} else {
		// 			if (nextImage == i) {
		// 				TweenMax.to(logo, .75, {autoAlpha:1, delay:.25});
		// 				current[num] = i;
		// 			}
		// 		}
		// 	}
		// }
		
		// function showElements() {
		// 	totalLogos = $('.companies:first .company').length;
		// 	TweenMax.set($('.companies .company'), {autoAlpha:0});
		// 	for (var j = 0; j<5 ;j++) {
		// 		for (var i = 0; i<totalLogos; i++) {
		// 			if (j == i && i<6) {
		// 				TweenMax.set($('.companies:eq('+j+') .company:eq('+i+')'), {autoAlpha:1});
		// 			}
		// 		}
		// 	}
		// 	//updateLogos();
		// }
		
		//showElements();
		var progressBar = $('#progress-bar');

		var tl = new TimelineMax({
			paused: false,
			repeat: -1
		});

		tl.to({}, 4.5, {
			force3D:true,
			onUpdateParams: ["{self}"],
			onUpdate: function(timeline) {
				TweenMax.set(progressBar, {
					scaleX: timeline.progress(),
					transformOrigin: "0px 0px"
				});
			},
			onComplete: function() {
				//var arr = updateLogos(current);
				var splice = spliceNum();				
				setTimeout(function(){
					showLogos(current,splice);					
			   }, 0);
			   setTimeout(function(){ 
				   $( "#img-logo-ram-1" ).animate({    
					   opacity: 1,
				   }, 750 );
				   $( "#img-logo-ram-2" ).animate({    
					   opacity: 1,
				   }, 750 );
				   $( "#img-logo-ram-3" ).animate({    
					   opacity: 1,
				   }, 750 );
			  }, 0);
			   setTimeout(function(){ 
					$( "#img-logo-ram-1" ).animate({    
						opacity: 0,
					}, 500 );
					$( "#img-logo-ram-2" ).animate({    
					   opacity: 0,
				   }, 500 );
				   $( "#img-logo-ram-3" ).animate({    
					   opacity: 0,
				   }, 500 );
			   }, 4000);			

			}
		});
		tl.play();

		// var photoContainer = document.getElementsByClassName('sbi_photo_wrap');
		// console.log(photoContainer);
		// for(var i = 0; i < photoContainer; i++) {
		// 	console.log(photoContainer[i]);
		// }
		// 	var overlay = document.createElement('div');
		// 	overlay.setAttribute('style', 'width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5);');
		// 	photoContainer.innerHTML(overlay);

		/////////// Inicio código para el modal /////////////
		$('.btn-cotizar').on('click', function(e) {
			e.preventDefault();
			$('#cotizacionModal').modal('show');
			$('#cotizacionModal').modal({backdrop: 'static', keyboard: false});
			$('body').css('padding-right','0');
			$('body').css('overflow','hidden');
			$('body').removeClass('overflow');
		});

		if(!$('body').hasClass('modal-open')){
			$('body').css('overflow','initial');
			console.log('hi');
		}

		$('.btn-evaluar').on('click', function(e) {
			e.preventDefault();
			$('#testModal').modal('show');
			$('body').css('overflow','hidden');			
		});

		$('#testModal .modal-dialog .modal-content .modal-header .close').on('click', function(e) {
			$('body').css('overflow','scroll');	
		});

		$('#cotizacionModal .modal-dialog .modal-content .modal-header .close').on('click', function(e) {
			$('body').css('overflow','scroll !important');	
			$('body').css('padding-right','0');	
			$('#home').css('overflow-x','hidden');
			$('#home').css('overflow-y','hidden');
			console.log('3');
			$('body').addClass('overflow');
			$('#quote-Empresas').trigger("reset");
	    	$('#quote-Viajero').trigger("reset");
	    	$('#quote-Incendio').trigger("reset");
	    	$('#quote-Vida').trigger("reset");
	    	$('#quote-Salud').trigger("reset");
	    	$('#quote-Automóvil').trigger("reset");	

		});

		$('#vistaCotizacion .fa-chevron-left').on('click', function(e) {
			$('#vistaCotizacion').modal('hide');
			$('#cotizacionModal').modal('show');			
		});

		$('#vistaCotizacion .modal-dialog .modal-content .modal-header .close').on('click', function(e) {
			$('body').css('overflow','scroll !important');	
			$('body').css('padding-right','0');
			$('#home').css('overflow-x','hidden');
			$('#home').css('overflow-y','hidden');
			console.log('2');
			$('body').addClass('overflow');		

		});



		$( ".quote-item" ).each(function(index) {
			$(this).on("click", function(){
				$('#vistaCotizacion').modal('show');
				$('#cotizacionModal').modal('hide');
/*				$('body').css('overflow','hidden');
				$('#home').css('overflow-x','initial');
				$('#home').css('overflow-y','initial');*/
				var data  = $(this).attr('data-quote');
				var title = $(this).html();
				console.log(data);

				switch(data){
					case  'quote-Automóvil' :
						$('#quote-Automóvil').css('display','block');
						$('#quote-Salud').css('display','none');
						$('#quote-Vida').css('display','none');
						$('#quote-Incendio').css('display','none');
						$('#quote-Viajero').css('display','none');
						$('#quote-Empresas').css('display','none');						
						break;
					case 'quote-Salud' :
						$('#quote-Salud').css('display','block');
						$('#quote-Automóvil').css('display','none');
						$('#quote-Vida').css('display','none');
						$('#quote-Incendio').css('display','none');
						$('#quote-Viajero').css('display','none');
						$('#quote-Empresas').css('display','none');	
						break;
					case 'quote-Vida' :
						$('#quote-Vida').css('display','block');
						$('#quote-Salud').css('display','none');
						$('#quote-Automóvil').css('display','none');
						$('#quote-Incendio').css('display','none');
						$('#quote-Viajero').css('display','none');
						$('#quote-Empresas').css('display','none');	
						break;
					case 'quote-Incendio' :
						$('#quote-Incendio').css('display','block');
						$('#quote-Salud').css('display','none');
						$('#quote-Vida').css('display','none');
						$('#quote-Automóvil').css('display','none');
						$('#quote-Viajero').css('display','none');
						$('#quote-Empresas').css('display','none');	
						break;
					case 'quote-Viajero' :
						$('#quote-Viajero').css('display','block');
						$('#quote-Salud').css('display','none');
						$('#quote-Vida').css('display','none');
						$('#quote-Incendio').css('display','none');
						$('#quote-Automóvil').css('display','none');
						$('#quote-Empresas').css('display','none');	
						break;
					case 'quote-Empresas' :
						$('#quote-Empresas').css('display','block');
						$('#quote-Salud').css('display','none');
						$('#quote-Vida').css('display','none');
						$('#quote-Incendio').css('display','none');
						$('#quote-Viajero').css('display','none');
						$('#quote-Automóvil').css('display','none');	
						break;
				}


				$('#modal-vista-cotizacion .modal-title').html(title);

			});
		});
	});

})(jQuery); 
